import { useTheme } from 'styled-components'

import LoaderLogo from '../../assets/svg/loader.1b103a97.webp'

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({
  size = '16px',
  stroke,
  strokeWidth,
  ...rest
}: {
  size?: string
  stroke?: string
  strokeWidth?: number
  [k: string]: any
}) {
  const theme = useTheme()
  return <img style={{ maxWidth: '150px' }} src={LoaderLogo} />
}

export function LoaderV2() {
  const theme = useTheme()
  return <img style={{ maxWidth: '150px' }} src={LoaderLogo} />
}

export function LoaderV3({ size = '16px', color, ...rest }: { size?: string; color?: string; [k: string]: any }) {
  const theme = useTheme()
  return <img style={{ maxWidth: '150px' }} src={LoaderLogo} />
}
